import React from 'react'
import Layout from '../components/Layout'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import {makeStyles, createStyles} from '@mui/styles'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import StatusCodeImage from '../images/404.png'


// create custom styles
const useStyles = makeStyles((theme:any) => (createStyles({
    errorImage: {
        verticalAlign: "middle",
        [theme.breakpoints.up("xl")]: {
            width: "500px"
        }
    },
    errorMessage: {
        [theme.breakpoints.up("xl")]: {
            fontSize: "5rem"
        }
    },
    miniMessage: {
        [theme.breakpoints.up("xl")]: {
            fontSize: "2rem"
        }
    },
    buttonSize: {
        [theme.breakpoints.up("xl")]: {
            fontSize: "2rem"
        }
    },
    gridContainer: {
        paddingTop: "10px",
        paddingBottom: "30px",
        [theme.breakpoints.down("sm")]: {
            paddingTop: "30px"
        }
    }

})))


export default function ErrorPage(
    props: React.PropsWithChildren<{
    navigation?: Pick<
    GatsbyTypes.Navigation,
    "title" | "slug" | "navigationChildren"
    >;
    location: Location
}>) {
    // create a 404 page using this settings
    const classes = useStyles()
    const theme = useTheme()
    const largeScreen = useMediaQuery(theme.breakpoints.up("xl"))

    return (
        <Layout location={props.location}>
            <Grid
            container
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            className={classes.gridContainer}
            >
                <img src={StatusCodeImage} alt="Page not Found" width="300px" className={classes.errorImage}/>
                <Typography variant={largeScreen ? "h1" : "h3"} component="div"
                sx={{color: "#000000"}} align="center"
                className={classes.errorMessage}
                >
                    404 Page Not Found
                    <Typography variant={largeScreen ? "h5" : "subtitle1"}>
                        It seems that you are <em>trying to access a page that does not exist</em>
                    </Typography>
                    <Typography variant={largeScreen ? "h5" : "subtitle1"} >
                        If you think something is broken, <strong>Report a Problem</strong>
                    </Typography>
                    <Button href="/" sx={{fontSize: {xl: "1.5rem"}}}>
                        Return to Home
                    </Button>
                    <Button href="mailto:joshua.a.esteves@accenture.com?subject=Report a Problem in SEM Wiki&body=Hi Admins,%0D%0A%0D%0AI have found a problem related to SEM Wiki the followings are:" sx={{fontSize: {xl: "1.5rem"}}}>
                        Report a Problem
                    </Button>
                </Typography>
            </Grid>
        </Layout>

    )
}
